import ArrowLink from 'src/components/shared/ArrowLink'
import Link from 'src/components/shared/Link'
import MarketplaceCardGrid from '../MarketplaceCardGrid'
import css from './styles.module.scss'

const MAX_NPM_PACKAGES = 6

function pickRandomItemsFromArray(array, max) {
  const remainingItems = array.slice(0)
  const pickedItems = []
  for (let i = 0; i < max; i++) {
    const index = Math.floor(Math.random() * remainingItems.length)
    pickedItems.push(remainingItems[index])
    remainingItems.splice(index, 1)
  }
  return pickedItems
}

const MarketplaceSection = ({ title, link, npmPackages, npmPackageType }) => {
  return (
    <section className={css.marketplaceSection}>
      <div className={css.marketplaceSection__header}>
        <h2 className={css.marketplaceSection__title}>{title}</h2>
        <Link
          href={link.href}
          as={link.as}
          className={css.marketplaceSection__headerLink}
        >
          <ArrowLink theme="blue-800">See all ({npmPackages.length})</ArrowLink>
        </Link>
      </div>
      <MarketplaceCardGrid
        npmPackages={pickRandomItemsFromArray(npmPackages, MAX_NPM_PACKAGES)}
        npmPackageType={npmPackageType}
      />
    </section>
  )
}

export default MarketplaceSection
