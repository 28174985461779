import Layout from 'components/shared/Layout'
import { Seo, SeoProvider } from 'components/shared/Seo'
import MarketplaceSection from 'components/pages/marketplace/MarketplaceSection'
import MarketplaceSubmitButton from 'components/pages/marketplace/MarketplaceSubmitButton'
import css from './styles.module.scss'
import getPackages from 'src/marketplace/services/get-packages'
import MarketplaceNavigation from 'src/components/pages/marketplace/MarketplaceNavigation'
import MarketplaceBlockSubmitPlugin from 'src/components/pages/marketplace/MarketplaceBlockSubmitPlugin'

const MarketPlacePage = ({ plugins, providers }) => {
  const seo = {
    metaTitle: 'Strapi Market',
    metaDescription:
      'Power up your Strapi app with the industry-leading software and easily add custom features using plugins.',
    shareImage: {
      media: {
        url: '/uploads/marketplace_seo_image_520c0ba977.png',
      },
    },
    marketplaceUrl: 'https://market.strapi.io',
  }

  return (
    <SeoProvider pageSeo={seo}>
      <Seo />
      <div className={css.marketplaceLayout}>
        <MarketplaceNavigation
          breadcrumbs={[
            {
              name: 'Home',
              isActive: true,
              link: {
                href: '/marketplace',
                as: '/',
              },
            },
          ]}
        />
        <Layout type="wrapper">
          <div className={css.marketplaceHomePage__heading}>
            <h1 className={css.marketplaceHomePage__heading__h1}>
              Marketplace
            </h1>
            <div className={css.headingContainer}>
              <div className={css.headingDescription}>
                <h3 className={css.marketplaceHomePage__heading__h3}>
                  Get more out of Strapi
                </h3>
                <p className={css.marketplaceHomePage__heading__p}>
                  Power up your Strapi app with the industry-leading software
                  and easily add custom features using plugins.
                </p>
              </div>
              <MarketplaceSubmitButton npmPackageType="plugin" />
            </div>
          </div>
          <MarketplaceSection
            title="Plugins"
            link={{
              href: '/marketplace/plugins',
              as: '/plugins',
            }}
            npmPackages={plugins}
            npmPackageType="plugin"
          />
          <MarketplaceSection
            title="Providers"
            link={{
              href: '/marketplace/providers',
              as: '/providers',
            }}
            npmPackages={providers}
            npmPackageType="providers"
          />
          <MarketplaceBlockSubmitPlugin />
        </Layout>
      </div>
    </SeoProvider>
  )
}

export async function getServerSideProps() {
  const npmPackages = await getPackages()

  return {
    props: npmPackages,
  }
}

export default MarketPlacePage
